import React from "react"

import Privacy from "./Privacy"

export interface PrivacyContainerProps {
  pageContext: { [key: string]: any }
}

const PrivacyContainer: React.FC<PrivacyContainerProps> = ({ pageContext }) => {
  return <Privacy {...pageContext} />
}

export default PrivacyContainer
