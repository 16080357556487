import React from "react"

import ContentContainer from "src/containers/ContentContainer"
import BackLink from "src/components/BackLink"
import Footer from "src/components/Footer"
import PrivacyContent from "./PrivacyContent"

export default function Privacy() {
  return (
    <>
      <ContentContainer>
        <BackLink link="/" text="Back" />
        <PrivacyContent />
      </ContentContainer>
      <Footer />
    </>
  )
}
